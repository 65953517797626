import { BatchType } from "@/interfaces/batchType";
import { Brand } from "@/interfaces/brand";
import { ProductCategory } from "@/interfaces/productCategoy";
import { batchTypeService } from "@/services/batchType.service";
import { brandService } from "@/services/brand.service";
import { productCategoryService } from "@/services/productCategory.service";
import debounce from "lodash/debounce";
import { Component, Vue, Watch } from "vue-property-decorator";

import Template from "./PricePointFilter.template.vue";

@Component({
  mixins: [Template]
})
export default class PricePointFilterComponent extends Vue {
  protected isLoadingCategories = false;
  protected isLoadingBrand = false;
  protected isLoadingBatchType = false;

  protected categories = null;
  protected brands = null;
  protected batchTypes = null;

  protected searchCategoryField = null;
  protected searchBrandField = null;
  protected searchBatchTypeField = null;

  protected categoryItems: ProductCategory[] = [];
  protected brandItems: Brand[] = [];
  protected batchTypeItems: BatchType[] = [];

  protected search = null;
  protected debounceTime = 500;

  private debounceCategorySearch = debounce(
    async (categoryName: string, scope: any) => {
      if (!categoryName || categoryName.length > 2) {
        scope.isLoadingCategories = true;
        scope.categoryItems = await productCategoryService.get({
          "q[name_contains]": categoryName
        });
        scope.isLoadingCategories = false;
      }
    },
    this.debounceTime
  );

  private debounceBrandSearch = debounce(
    async (brandName: string, scope: any) => {
      if (!brandName || brandName.length > 2) {
        scope.isLoadingBrand = true;
        scope.brandItems = await brandService.get({
          "q[name_contains]": brandName
        });
        scope.isLoadingBrand = false;
      }
    },
    500
  );

  private debounceBatchTypeSearch = debounce(
    async (batchTypeName: string, scope: any) => {
      if (!batchTypeName || batchTypeName.length > 2) {
        scope.isLoadingBatchType = true;
        scope.batchTypeItems = await batchTypeService.get({
          "q[name_contains]": batchTypeName
        });
        scope.isLoadingBatchType = false;
      }
    },
    500
  );

  @Watch("searchCategoryField")
  public searchCategory(categoryName: string) {
    this.debounceCategorySearch(categoryName, this);
  }

  @Watch("searchBrandField")
  public searchBrands(brandName: string) {
    this.debounceBrandSearch(brandName, this);
  }

  @Watch("searchBatchTypeField")
  public searchBatchType(batchTypeName: string) {
    this.debounceBatchTypeSearch(batchTypeName, this);
  }

  public setFilters() {
    this.$emit("setFilters", {
      product_category_id: this.categories,
      brand_id: this.brands,
      batch_type_id: this.batchTypes
    });
  }

  public mounted() {
    this.debounceCategorySearch("", this);
    this.debounceBrandSearch("", this);
    this.debounceBatchTypeSearch("", this);
  }
}
