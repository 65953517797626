import PricePointFilterComponent from "@/components/inventory/pricePoint/pricePointFilter/PricePointFilter.component";
import PricePointStructureComponent from "@/components/inventory/pricePoint/pricePointStructure/PricePointStructure.component";
import { Location } from "@/interfaces/location";
import { PriceGroupInterface } from "@/interfaces/priceGroups";
import { PricingRule } from "@/interfaces/product";
import { priceGroupService } from "@/services/priceGroup.service";
import { pricePointService } from "@/services/pricePoint.service";
import { PageNavAction } from "@/types/types";
import { ActionsSubheaderComponent } from "helix-vue-components";
import find from "lodash/find";
import forEach from "lodash/forEach";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./PricePoint.template.vue";

/**Î
 * product price point component
 */
@Component({
  mixins: [Template],
  components: {
    PricePointStructureComponent,
    PricePointFilterComponent
  }
})
export default class PricePointComponent extends Vue {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  public priceGroupItems: PriceGroupInterface[] = [];
  public isSaving = false;
  public showPriceGroupDropdown = false;
  public priceGroupId: number | null = null;
  public pricingType: string | null = null;
  public loadingPriceGroup = false;
  public isCustomOption = false;

  get hasPrices() {
    return !!this.priceRules.length;
  }

  protected tax = {
    RECREATIONAL: null,
    MEDICAL: null
  };

  private priceRules: PricingRule[] = [];
  private filter: { [key: string]: any } = {};

  public getPricesToDelete(oldPrices: PricingRule[], newPrices: PricingRule[]) {
    const pricesToDelete: PricingRule[] = [];
    forEach(oldPrices, price => {
      const onFinalPrices = find(newPrices, el => {
        return price.id === el.id;
      });
      if (!onFinalPrices) {
        price._destroy = true;
        pricesToDelete.push(price);
      }
    });
    return pricesToDelete;
  }

  public async assignSelectedPriceGroupRules() {
    if (this.pricingType === "PRICE_GROUP" && this.priceGroupId) {
      const selectedItemIndex = this.priceGroupItems.findIndex(
        item => item.pricing_group_id === this.priceGroupId
      );
      const priceGroup = this.priceGroupItems[selectedItemIndex];
      this.priceRules = [];
      if (priceGroup && priceGroup.rules && priceGroup.rules.length) {
        priceGroup.rules.map((item: PricingRule) => {
          if (item.id) {
            delete item.id;
          }
        });
        this.priceRules = priceGroup.rules;
      }
    } else if (this.pricingType === "CUSTOM") {
      this.priceGroupId = null;
      this.priceRules = [];
    }
    this.showPriceGroupDropdown =
      this.pricingType === "PRICE_GROUP" ? true : false;
    this.isCustomOption = this.pricingType === "CUSTOM" ? true : false;
  }

  public goBack() {
    this.$router.push({ name: "pricing-tools" });
  }

  public clear() {
    this.priceRules = [];
  }

  public async save() {
    this.isSaving = true;
    const response = await pricePointService.savePrices(
      this.priceRules,
      this.filter,
      Number(this.priceGroupId)
    );
    if (response) {
      this.priceRules = [];
    }
    this.isSaving = false;
  }

  public setFilters(filters: {
    product_category_id: string[] | null;
    brand_id: number[] | null;
    batch_type_id: string[] | null;
    [key: string]: any;
  }) {
    Object.keys(filters).forEach((key: string) => {
      if (filters[key]) {
        this.filter[key] = filters[key];
      }
    });
  }

  public async getPriceGroupsList() {
    this.loadingPriceGroup = true;
    const items = await priceGroupService.getPriceGroupList();
    this.priceGroupItems = items.data;
    this.loadingPriceGroup = false;
  }

  public async mounted() {
    this.getPriceGroupsList();
    this.setPageNav({
      title: "price_points",
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-plus",
            action: this.clear
          },
          {
            icon: "fal fa-save",
            action: this.save,
            vuetifyProps: () => ({
              disabled: !this.hasPrices || this.isSaving,
              loading: this.isSaving,
              fab: true,
              small: true
            })
          }
        ]
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              icon: "fal fa-chevron-left",
              action: this.goBack
            }
          ]
        }
      }
    });
  }
}
